import React from 'react';
import Image from 'next/image';
import styles from './footer.module.scss';
import LoginLink from 'src/components/LoginLink/LoginLink';
import Routes from 'src/constants/Routes';

const items = [
    {
        heading: 'Legal',
        list: [
            {
                label: 'Terms of use',
                url: Routes.TERMS_OF_USE,
            },
            {
                label: 'Privacy policy',
                url: Routes.PRIVACY_POLICY,
            },
        ],
    },
    {
        heading: 'About',
        list: [
            {
                label: 'Knowledge hub',
                url: Routes.BLOG,
            },
            {
                label: 'About us',
                url: Routes.ABOUT_US,
            },
            {
                label: 'Sitemap',
                url: Routes.SITEMAP,
            },
        ],
    },
    {
        heading: 'Account',
        list: [
            {
                label: 'Login',
            },
            {
                label: 'Payment options',
                image: {
                    src: '/images/icons/credit-cards.png',
                    width: 187,
                    height: 24,
                },
                url: null,
            },
            {
                label: 'Australia Post ID verification',
                image: {
                    src: '/images/icons/digital-id-logo.svg',
                    width: 33,
                    height: 40,
                },
                url: null,
            },
        ],
    },
] as const;

export default function FooterNavigation() {
    return (
        <React.Fragment>
            {items.map((block, index) => (
                <div
                    className={
                        styles['navigation'] +
                        ' mb-2 w-full last:pt-0 md:w-1/3 lg:mb-6'
                    }
                    key={index}
                >
                    <h5 className="highlight pb-2 pt-4 text-left text-white">
                        {block.heading}
                    </h5>
                    <div
                        className="w-5 border-t border-white"
                        aria-hidden="true"
                    />
                    <ul className={styles['navigation-list']}>
                        {block.list.map((e, i) => (
                            <li key={i}>
                                {e.label === 'Login' ? (
                                    <LoginLink />
                                ) : e.url ? (
                                    <a
                                        href={e.url}
                                        title={e.label}
                                        className="block pb-4"
                                        target={e.linkTarget}
                                    >
                                        {e.image ? (
                                            <Image
                                                src={e.image}
                                                alt={e.label}
                                                width={e.width}
                                                height={e.height}
                                            />
                                        ) : (
                                            e.label
                                        )}
                                    </a>
                                ) : (
                                    <React.Fragment>
                                        {e.image ? (
                                            <Image
                                                src={e.image.src}
                                                height={e.image.height}
                                                width={e.image.width}
                                                className={
                                                    styles['footer-logos']
                                                }
                                                alt={e.label}
                                            />
                                        ) : (
                                            e.label
                                        )}
                                    </React.Fragment>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </React.Fragment>
    );
}
