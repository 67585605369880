import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';
import { ReactNode, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './accordion.module.scss';

export type AccordionItemProps = {
    title: string;
    isActive?: boolean;
    activeColor?: string;
    onClick?: () => void;
    content: string | ReactNode;
};

const AccordionItem = ({
    title,
    isActive = false,
    activeColor,
    onClick,
    content,
}: AccordionItemProps) => {
    // Find when the accordion item is in the middle
    const { ref, inView } = useInView({
        rootMargin: '-40% 0px -60% 0px',
    });
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const button = containerRef.current;
        if (button && activeColor)
            (button.parentNode as HTMLDivElement).style.setProperty(
                '--active-color',
                activeColor
            );
    }, [activeColor]);

    useEffect(() => {
        if (isActive) {
            // Wait for accordion to expand before scrolling
            setTimeout(() => {
                if (
                    containerRef?.current &&
                    // Check if the accordion is not in view or larger than the viewport
                    (containerRef.current.clientHeight > window.innerHeight ||
                        containerRef.current.getBoundingClientRect().top < 0)
                ) {
                    const top =
                        containerRef.current.getBoundingClientRect().top +
                        window.pageYOffset -
                        16;
                    window.scrollTo({
                        top,
                        behavior: 'smooth',
                    });
                }
            }, 100);
        }
    }, [isActive]);

    return (
        <div
            ref={containerRef}
            className={classNames(styles['accordion__item'])}
            data-expanded={isActive}
            data-in-view={inView}
            data-testid="accordion-item"
        >
            <button
                ref={ref}
                className={styles['accordion__button']}
                onClick={() => {
                    onClick && onClick();
                }}
            >
                {HTMLReactParser(title)}
                <FontAwesomeIcon icon={faPlus} data-active={isActive} />
            </button>
            <div className={styles['accordion__content']} role="region">
                {typeof content === 'string'
                    ? HTMLReactParser(content)
                    : content}
            </div>
        </div>
    );
};

export default AccordionItem;
