import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './social-media-list.module.scss';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Routes from 'src/constants/Routes';

const list = [
    {
        label: "Facebook",
        icon: faFacebook,
        altText: "Follow us on Facebook",
        url: Routes.FACEBOOK,
    },
    {
        label: "Instagram",
        icon: faInstagram,
        altText: "Follow us on Instagram",
        url: Routes.INSTAGRAM,
    }
] as const;

export default function SocialMediaList() {
    return (
        <div className={styles['social-media-list']}>
            {list.map((item, index) => (
                <a
                    className={styles['links']}
                    key={index}
                    title={item.altText}
                    href={item.url}
                    target={'_blank'}
                >
                    <FontAwesomeIcon icon={item.icon} />
                </a>
            ))}
        </div>
    );
}
