'use client';

import { useState } from 'react';
import styles from './accordion.module.scss';
import AccordionItem, { type AccordionItemProps } from './AccordionItem';

type AccordionProps = {
    activeColor?: string;
    initialOpenItem?: number;
    items: AccordionItemProps[];
    onItemOpen?: (item: AccordionItemProps) => void;
};

const Accordion = ({
    activeColor,
    initialOpenItem,
    items,
    onItemOpen,
}: AccordionProps) => {
    const [selectedItem, setSelectedItem] = useState<number | null | undefined>(
        initialOpenItem,
    );

    const toggleAccordionItem = (id: number, item: AccordionItemProps) => {
        if (selectedItem === id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(id);
            onItemOpen && onItemOpen(item);
        }
    };

    return (
        <div className={styles['accordion']}>
            {items.map((item, id) => (
                <AccordionItem
                    key={id}
                    title={item.title}
                    content={item.content}
                    isActive={selectedItem === id}
                    activeColor={activeColor}
                    onClick={() => toggleAccordionItem(id, item)}
                />
            ))}
        </div>
    );
};

export default Accordion;
