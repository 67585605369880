import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';
import Image from 'next/image';


const list = [
    {
        label: 'Mosh',
        image: '/images/home/footer/mosh-icon.png',
        titleImage: {
            src: '/images/home/footer/mosh-title.png',
            width: 64,
            height: 30,
            alt: 'label'
        },
        text: 'Visit getmosh.com.au',
        url: 'https://www.getmosh.com.au/?utm_source=Moshy&utm_medium=website-footer&utm_campaign=Moshy_Website_Footer-Mosh&utm_content=mosh-footer-banner&utm_term=male-dedicated-brand-mosh',
    },
    {
        label: 'THM',
        image: '/images/home/footer/thm-icon.png',
        titleImage: {
            src: '/images/home/footer/thm-title.png',
            width: 113,
            height: 34,
            alt: 'label'
        },
        text: 'Visit www.healthymummy.com',
        url: 'https://www.healthymummy.com/?utm_source=Moshy&utm_medium=Moshy-footer&utm_campaign=our-brands'
    }
] as const;

export default function MoshyLogoLink() {
    return (
        <section>
            <div className="font-body font-semibold text-grey-300 mt-6 tracking-[-.01em]">
                Our brands
            </div>
            <div className="xl:flex xl:gap-2">
                {list.map((item, index) => (
                    <a
                        className="flex xl:w-fit"
                        key={index}
                        title={item.label}
                        href={item.url}
                        target="_blank"
                    >
                        <div className="max-sm:w-[100%] flex xl:w-fit bg-green-faint-dark shadow-[0_5px_20px_-10px_rgba(19,40,18,0.1)] rounded-2xl mt-2">
                            <Image className="object-contain m-4"
                                src={item.image}
                                width={52}
                                height={52}
                                alt={item.label}
                            />
                            <div className="max-sm:w-[60%] my-4">
                                <Image className="object-contain h-[30px]"
                                    src={item.titleImage.src}
                                    width={item.titleImage.width}
                                    height={item.titleImage.height}
                                    alt={item.label}
                                />
                                <div className="font-body font-normal text-xs leading-[120%] text-grey-200 mt-1">
                                    {item.text}
                                </div>
                            </div>
                            <div className="flex items-center p-4">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </section>
    );
}
