'use client';

import classNames from 'classnames';
import Accordion from 'src/components/Accordion';

import styles from './faqs.module.scss';
import { ButtonAction, EventProps } from 'src/analytics/EventProps';
import { trackLandingPageButtonClick } from 'src/analytics/Events';

export type FaqProps = {
    className?: string;
    title?: string;
    list: FaqListType[];
    eventProps: EventProps;
};

type FaqListType = {
    title: string;
    content: string | JSX.Element;
};

const Faq = ({ className, title = 'FAQs', list, eventProps }: FaqProps) => {
    const onItemOpen = (item: FaqListType) => {
        trackLandingPageButtonClick({
            location: 'FAQ',
            label: item.title,
            button_action: ButtonAction.ACCORDION,
            ...eventProps,
        });
    };

    return (
        <section className={classNames(styles['faq-section'], className)}>
            <h2>{title}</h2>
            <Accordion items={list} onItemOpen={onItemOpen} />
        </section>
    );
};

export default Faq;
